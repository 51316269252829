<template>
  <responsive-menu
    :color="colorPreset"
    :activator-title="nameToDisplay"
    :icon="icon"
    left
    nudge-bottom=4
    @input="menuStateChange"
    v-model="innerMenuIsOpen"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="border-radius"
        :color="colorPreset"
        height=32
        small
        v-on="on"
      >
        <v-icon
          v-if="icon"
          left
        >
          {{icon}}
        </v-icon>
        <icon-tooltip
          v-if="isDeleted"
          class="mr-2"
          color="error"
          small
          icon-name="i.ExclamationThick"
          :tooltip-text="$t('t.DocumentIsDeleted')"
        />
        <span class="text-transform-none text-truncate max-width-text nowrap">{{nameToDisplay}}</span>
      </v-btn>
    </template>
    <suggestion-list
      :can-edit-column="true"
      color="primary"
      :items="items"
      itemKey="id"
      :loading="search.loading"
      :value="activeItem"
      @click:item="$emit('input', $event.item)"
      @end-reached="search.retrieveNextPage()"
    >
      <template v-slot:append-action="{ item }">
        <v-list-item-action>
          <v-btn
            v-if="item.id && !item.static"
            icon
            small
            @click.stop="edit(item)"
          >
            <icon-tooltip
              small
              :icon-name="'i.OpenInSidePanel'"
              :tooltip-text="$t('t.OpenInSidePanel')"
            />
          </v-btn>
        </v-list-item-action>
      </template>
    </suggestion-list>
    <div class="d-flex flex-column px-4 mt-4 border-radius">
      <v-btn
        class="border-radius"
        @click="edit()"
        text
        color="primary"
      >
        <v-icon class="mr-1">{{ $icon('i.PlusCircle') }}</v-icon>{{ $t('t.Add') }}
      </v-btn>
    </div>
  </responsive-menu>
</template>

<script>
import { DocSync, getDoc, CacheType } from '@/wasm/pkg'
import IncExc from '@/pages/search/controllers/inc-exc'
import Search from '@/pages/search/controllers'
import sidePanelController from '@/sidePanelController'

export default {
  components: {
    ResponsiveMenu: () => import('@/pages/search/components/responsive-menu'),
    SuggestionList: () => import('@/pages/search/components/suggestion-list'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  activated () {
    this.docSync.active = true
  },
  beforeDestroy () {
    this.search = undefined
    this.docSync.active = false
  },
  computed: {
    colorPreset () {
      if (
        !this.items.find(item => item.id === this.activeItem)?.static &&
        !sidePanelController.isSidePanelHidden &&
        !this.value &&
        ((this.isColumnSet && this.isMainSearchEqualToSidePanelDisplayedColumnSet) || (this.isSortSet && this.isMainSearchEqualToSidePanelDisplayedSortSet))
      ) {
        return 'secondary'
      } else {
        return 'primary'
      }
    },
    isMainSearchEqualToSidePanelDisplayedDocument () {
      return this.mainSearch.documentType === sidePanelController?.displayedDocument?.params?.targetDocTy &&
        this.mainSearch.scope?.type === sidePanelController?.displayedDocument?.params?.scope?.type
    },
    isMainSearchEqualToSidePanelDisplayedColumnSet () {
      return this.isMainSearchEqualToSidePanelDisplayedDocument && sidePanelController?.displayedDocument?.type === 'column-sets'
    },
    isMainSearchEqualToSidePanelDisplayedSortSet () {
      return this.isMainSearchEqualToSidePanelDisplayedDocument && sidePanelController?.displayedDocument?.type === 'sort-sets'
    },
    isColumnSet () {
      return this.type === 'column-sets'
    },
    isSortSet () {
      return this.type === 'sort-sets'
    },
    isDeleted () {
      return this.obs?.doc?.isDeleted ?? false
    },
    items () {
      return this.prependItem ? [this.prependItem, ...this.search.items] : this.search.items
    },
    nameToDisplay () {
      if (this.isColumnSet) {
        return this.columnSetNameToDisplay()
      }

      if (this.isSortSet) {
        return this.sortSetNameToDisplay()
      }

      return this.$t('t.New')
    }
  },
  deactivated () {
    this.docSync.active = false
  },
  data () {
    const docSync = new DocSync()
    docSync.cacheType = this.$cacheTypeFromString(this.type)

    return {
      docSync,
      innerMenuIsOpen: false,
      loadingName: true,
      name: undefined,
      obs: docSync.obs,
      search: new Search().chain(s => s.searchedDocumentTypes.include([this.type])),
      nameCustomToDisplay: null
    }
  },
  methods: {
    edit (item) {
      sidePanelController.displayDocument(item?.id, this.type, { targetDocTy: this.mainSearch?.documentType, scope: this.mainSearch?.scope })
      this.innerMenuIsOpen = false
    },
    menuStateChange (isOpen) {
      if (isOpen) {
        this.search
          .clearItems()
          .setFilters({ 'document-types': new IncExc().include([this.mainSearch.documentType]) })
          .setPage(1) // Page 1 must be enforced for proper reloading when changing mainSearch.documentType
          .execute()
      }
      this.$emit('update:menu-is-open', isOpen)
    },
    columnSetNameToDisplay () {
      return this.value ? this.name : this.mainSearch.customColumnSetId ? '* ' + this.nameCustomToDisplay : this.$t('t.New')
    },
    sortSetNameToDisplay () {
      if (this.activeItem === 'relevance') {
        return this.$t('t.Relevance')
      }

      return this.value ? this.name : this.mainSearch.customSortSetId ? '* ' + this.nameCustomToDisplay : this.$t('t.New')
    }
  },
  mounted () {
    this.docSync.active = true
  },
  watch: {
    'obs.doc.name' (name) {
      if (name) {
        this.name = name
      }
    },
    'mainSearch.scope': {
      immediate: true,
      handler (scope) {
        this.search.setCameleonScope(scope)
      }
    },
    menuIsOpen: {
      immediate: true,
      handler (menuIsOpen) {
        this.innerMenuIsOpen = menuIsOpen
      }
    },
    value: {
      immediate: true,
      handler (id) {
        if (id) {
          this.docSync.id = id
        }
      }
    },
    'mainSearch.customColumnSetId': {
      async handler (id) {
        if (id && this.isColumnSet) {
          const doc = await getDoc(CacheType.ColumnSetRef, id)
          this.nameCustomToDisplay = doc?.name
        }
      }
    },
    'mainSearch.customSortSetId': {
      async handler (id) {
        if (id && this.isSortSet) {
          const doc = await getDoc(CacheType.SortSetRef, id)
          this.nameCustomToDisplay = doc?.name
        }
      }
    }
  },
  props: {
    activeItem: String,
    icon: String,
    mainSearch: {
      required: true,
      type: Search
    },
    menuIsOpen: Boolean,
    prependItem: { Object, String },
    type: {
      required: true,
      type: String
    },
    value: {
      type: String
    }
  }
}
</script>
